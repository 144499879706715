import { getComponent } from './layoutBuilder';
import { leftPaneWidths } from './layoutsettings';

export default function buildDefaultLayout({
  bottomStack = { stack: [] },
  leftPaneWidth = leftPaneWidths.default,
  rightPaneWidth = 100 - leftPaneWidths.default,
  setActiveTab = () => {},
  topLeftStack = { stack: [] },
  topRightStack = [],
}) {
  const context = {
    type: 'stack',
    id: 'aside-stack',
    isClosable: false,
    hasHeaders: false,
    width: leftPaneWidth,
    key: 'aside-stack',
    content: [
      {
        type: 'react-component',
        id: 'aside',
        component: 'aside',
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        reactComponent: getComponent()('aside'),
        title: 'Instructions',
        isClosable: false,
      },
    ],
  };

  const exercise = {
    id: 'content',
    type: 'column',
    width: rightPaneWidth,
    key: 'content',
    isClosable: false,
    content: [
      {
        type: 'row',
        height: 60,
        id: 'content-top',
        isClosable: false,
        content: [
          {
            type: 'stack',
            onActiveItemChanged: (e: any) => {
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
              setActiveTab(e.config.parentKey, e.config.key);
            },
            id: 'top-left-stack',
            cssClass: 'gl-topleft-stack',
            isClosable: false,
            content: topLeftStack.stack,
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'activeItemIndex' does not exist on type ... Remove this comment to see the full error message
            activeItemIndex: topLeftStack.activeItemIndex,
          },
          {
            type: 'stack',
            onActiveItemChanged: (e: any) => {
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
              setActiveTab(e.config.parentKey, e.config.key);
            },
            id: 'top-right-stack',
            isClosable: false,
            content: topRightStack,
          },
        ],
      },
      {
        type: 'stack',
        id: 'bottom-stack',
        isClosable: false,
        onActiveItemChanged: (e: any) => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
          setActiveTab(e.config.parentKey, e.config.key);
        },
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'activeItemIndex' does not exist on type ... Remove this comment to see the full error message
        activeItemIndex: bottomStack.activeItemIndex,
        content: bottomStack.stack,
      },
    ],
  };

  return {
    type: 'row',
    id: 'root',
    content: [context, exercise],
  };
}
