import type { Language } from '@datacamp/multiplexer-client';
import { useToast } from '@datacamp/waffles/toast';
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs';

import type { Technology } from '../../../helpers/technology';
import { useSelector } from '../../../interfaces/State';
import * as selectors from '../../../redux/selectors';

const FAKE_SESSION_TIMEOUT = 7000;
const MESSAGE_INTERVAL = 2500;

const getLoadingTexts = ({
  language,
  runtimeConfig,
  technology,
}: {
  language: Language;
  runtimeConfig: string | undefined;
  technology: Technology;
}): string[] => {
  if (language === 'sql') {
    if (technology === 'Snowflake') {
      return [
        'Starting Snowflake Environment',
        'Configuring Snowflake Data Warehouse',
        'Connecting to Snowflake Warehouse',
      ];
    }
    if (technology === 'BigQuery') {
      return [
        'Booting up BigQuery Engine',
        'Arranging BigQuery Data Sets',
        'Connecting to BigQuery Engine',
      ];
    }
    return [
      runtimeConfig === 'oracle' ? 'Firing up Oracle' : 'Firing up SQL server',
      'Preparing database',
      'Starting SQL client',
      'Initializing exercise',
    ];
  }
  if (language === 'r' && runtimeConfig === 'spark') {
    return [
      'Recycling Spark cluster if possible',
      'Starting Spark session',
      'Launching Spark executor',
      'Setting up HiveMetaStore',
      'Initializing DAGScheduler',
      'Connecting R to Spark',
      'Fetching datasets from disk',
      'Storing datasets into cluster',
      'Preparing cluster with data',
      'Initializing exercise',
    ];
  }
  if (language === 'python' && runtimeConfig === 'spark') {
    return [
      'Setting up Spark log profile',
      'Setting up HiveMetaStore',
      'Set up Spark session',
      'Initializing Spark context',
      'Connecting Python to Spark',
      'Initializing exercise',
    ];
  }
  if (language === 'python' && technology === 'Redshift') {
    return [
      'Starting Redshift Cluster',
      'Organizing Redshift Data Schemas',
      'Connecting to Redshift Cluster',
    ];
  }
  return [];
};

export default function useFakeSessionStatusToasts(): {
  fakingSessionIsInitialised: boolean;
} {
  const { toast } = useToast();
  const [fakingSessionIsInitialised, setFakingSessionIsInitialised] = useState(
    true,
  );
  const isInitSession = useSelector(selectors.isSessionInitializing);
  const language = useSelector(selectors.selectLanguage);
  const runtimeConfig = useSelector(selectors.selectRuntimeConfig);
  const technology = useSelector(selectors.selectTechnology);

  const text$ = useMemo(() => {
    const texts = getLoadingTexts({ language, runtimeConfig, technology });
    return Rx.Observable.timer(FAKE_SESSION_TIMEOUT, MESSAGE_INTERVAL)
      .takeWhile((i) => i < texts.length)
      .map((i) => texts[i]);
  }, [language, runtimeConfig, technology]);

  useEffect(() => {
    let subscriber: Rx.Subscription | undefined;
    if (isInitSession) {
      subscriber = text$.subscribe((currentText) => {
        toast({
          title: currentText,
          variant: 'success',
        });
      });
    }
    return () => subscriber?.unsubscribe();
  }, [isInitSession, text$, toast]);

  useEffect(() => {
    let timer: number | undefined;
    if (isInitSession) {
      timer = setTimeout(() => {
        setFakingSessionIsInitialised(false);
      }, FAKE_SESSION_TIMEOUT);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isInitSession]);

  return { fakingSessionIsInitialised };
}
