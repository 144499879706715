/* eslint-disable no-param-reassign */
import GoldenLayout from 'golden-layout';
import { version } from 'golden-layout/package.json';
import attempt from 'lodash/attempt';
import concat from 'lodash/concat';
import difference from 'lodash/difference';
import each from 'lodash/each';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import tabScroll, { disposeScrolls } from '../../helpers/tabScroll';
import * as actions from '../../redux/actions';

import { ConnectedTranslationsProvider } from './ConnectedTranslationsProvider';

// Make it accessible to GoldenLayout
window.React = React;
window.ReactDOM = ReactDOM;

if (version !== '1.5.9') {
  throw new Error('Check if the GoldenLayout monkey patch is still relevant.');
}

// Monkey patch Golden Layout library to be compatible with React 16
// https://reactjs.org/blog/2017/09/26/react-v16.0.html#breaking-changes (ReactDOM.render can return null)
// @ts-expect-error ts-migrate(2339) FIXME: Property '__lm' does not exist on type 'typeof Gol... Remove this comment to see the full error message
Object.assign(GoldenLayout.__lm.utils.ReactComponentHandler.prototype, {
  _render() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    ReactDOM.render(
      // @ts-expect-error ts-migrate(2339) FIXME: Property '_getReactComponent' does not exist on ty... Remove this comment to see the full error message
      this._getReactComponent(),
      // @ts-expect-error ts-migrate(2339) FIXME: Property '_container' does not exist on type '{ _r... Remove this comment to see the full error message
      this._container.getElement()[0],
      function render() {
        // @ts-expect-error ts-migrate(2339) FIXME: Property '_reactComponent' does not exist on type ... Remove this comment to see the full error message
        self._reactComponent = this;

        // @ts-expect-error ts-migrate(2339) FIXME: Property '_reactComponent' does not exist on type ... Remove this comment to see the full error message
        if (!self._reactComponent) {
          return;
        }

        // @ts-expect-error ts-migrate(2339) FIXME: Property '_originalComponentWillUpdate' does not e... Remove this comment to see the full error message
        self._originalComponentWillUpdate =
          // @ts-expect-error ts-migrate(2339) FIXME: Property '_reactComponent' does not exist on type ... Remove this comment to see the full error message
          self._reactComponent.componentWillUpdate || function noop() {};
        // @ts-expect-error ts-migrate(2339) FIXME: Property '_reactComponent' does not exist on type ... Remove this comment to see the full error message
        self._reactComponent.componentWillUpdate = self._onUpdate.bind(self);
        // @ts-expect-error ts-migrate(2339) FIXME: Property '_container' does not exist on type '{ _r... Remove this comment to see the full error message
        if (self._container.getState()) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property '_reactComponent' does not exist on type ... Remove this comment to see the full error message
          self._reactComponent.setState(self._container.getState());
        }
      },
    );
  },
});

// Monkey patch Golden Layout library (instead of using our own fork)
// @ts-expect-error ts-migrate(2339) FIXME: Property '__lm' does not exist on type 'typeof Gol... Remove this comment to see the full error message
Object.assign(GoldenLayout.__lm.container.ItemContainer.prototype, {
  // The setSize function accepts pixels as input, but uses percentages internally.
  // The function is used for the collapse panels functionality.
  // It fails to calculate the size of the container, hence setting the size was broken.
  // This patch probably only works, when the panels are not nested.
  setSize(width: any, height: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'parent' does not exist on type '{ setSiz... Remove this comment to see the full error message
    let rowOrColumn = this.parent;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let rowOrColumnChild = this;

    while (!rowOrColumn.isColumn && !rowOrColumn.isRow) {
      rowOrColumnChild = rowOrColumn;
      rowOrColumn = rowOrColumn.parent;

      // No row or column has been found
      if (rowOrColumn.isRoot) {
        return false;
      }
    }

    const direction = rowOrColumn.isColumn ? 'height' : 'width';
    const newSize = direction === 'height' ? height : width;

    // original: totalPixel = this[direction] * (100 / rowOrColumnChild.config[direction]);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'layoutManager' does not exist on type '{... Remove this comment to see the full error message
    const totalPixel = this.layoutManager[direction];
    const percentage = (newSize / totalPixel) * 100;
    const delta =
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'config' does not exist on type '{ setSiz... Remove this comment to see the full error message
      (rowOrColumnChild.config[direction] - percentage) /
      (rowOrColumn.contentItems.length - 1);

    for (let i = 0; i < rowOrColumn.contentItems.length; i += 1) {
      if (rowOrColumn.contentItems[i] === rowOrColumnChild) {
        rowOrColumn.contentItems[i].config[direction] = percentage;
      } else {
        rowOrColumn.contentItems[i].config[direction] += delta;
      }
    }

    rowOrColumn.callDownwards('setSize');

    return true;
  },
});

const hcGLWrap = (store: any, router: any) => (
  WrappedComponent: any,
  id: any,
) => {
  // Don't make stateless, need lifecycle functions
  class Wrapper extends React.Component {
    element: any;

    getChildContext() {
      return { router };
    }

    render() {
      return (
        <div
          id={`gl-${id}`}
          className="gl-content"
          ref={(element) => {
            this.element = element;
          }}
        >
          <Provider store={store}>
            <ConnectedTranslationsProvider>
              <WrappedComponent {...this.props} />
            </ConnectedTranslationsProvider>
          </Provider>
        </div>
      );
    }
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'childContextTypes' does not exist on typ... Remove this comment to see the full error message
  Wrapper.childContextTypes = {
    router: PropTypes.object,
  };
  return Wrapper;
};

// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
const computeConfigDiff = (config: any, nextConfig: any, path: any) => {
  if (config && nextConfig && config.content && nextConfig.content) {
    const nextConfigIds = map(nextConfig.content, 'id');
    const configIds = map(config.content, 'id');
    const removed = difference(configIds, nextConfigIds);
    const added = difference(nextConfigIds, configIds);
    // @ts-expect-error ts-migrate(7022) FIXME: 'diffs' implicitly has type 'any' because it does ... Remove this comment to see the full error message
    let diffs = [
      ...removed.map((r) => ({ kind: 'R', path, id: r })),
      ...added.map((a) => {
        const index = nextConfigIds.indexOf(a);
        return {
          kind: 'A',
          path,
          at: index,
          item: nextConfig.content[index],
          inItem: nextConfig.id,
        };
      }),
      ...flatMap(config.content, (item, i) =>
        computeConfigDiff(
          item,
          find(nextConfig.content, (c) => c.id === item.id),
          concat(path, ['content', i]),
        ),
      ),
    ];
    if (
      config.type === 'stack' &&
      !isUndefined(nextConfig.activeItemIndex) &&
      config.activeItemIndex !== nextConfig.activeItemIndex
    ) {
      diffs = diffs.concat([
        {
          kind: 'I',
          activeItemId: nextConfig.content[nextConfig.activeItemIndex].id,
          parent: nextConfig.id,
        },
      ]);
    }
    return diffs;
  }
  return [];
};

class GoldenComponent extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  compListerners: any;

  glLayout: any;

  onStateChange: any;

  onWindowResize: any;

  root: any;

  stackListeners: any;

  wrapper: any;

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'config' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.glLayout = new GoldenLayout(this.props.config, this.root);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'store' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.wrapper = hcGLWrap(this.props.store, this.context.router);

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'config' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.register(this.props.config);
    this.onWindowResize = () => {
      this.glLayout.updateSize();
    };

    this.onStateChange = () => {
      // @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
      const extractComponent = (node: any) => {
        if (!isArray(node.contentItems)) {
          return [];
        }
        const { components = [], others = [] } = groupBy(
          node.contentItems,
          ({ type }) => (type === 'stack' ? 'components' : 'others'),
        );
        return components.concat(flatMap(others, extractComponent));
      };

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'store' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.store.dispatch(
        actions.resizeEditors({
          components: reduce(
            extractComponent(this.glLayout.root),
            (components, component) => ({
              ...components,
              [component.config.id]: component.childElementContainer,
            }),
            {},
          ),
          dimensions: this.glLayout.config.dimensions,
        }),
      );
    };

    window.addEventListener('resize', this.onWindowResize);
    this.glLayout.on('stateChanged', this.onStateChange);

    this.glLayout.on('itemCreated', (item: any) => {
      // Add custom css classes
      if (item.config.cssClass) {
        item.element.addClass(item.config.cssClass);
      }

      if (item.config.type === 'stack') {
        item.element.attr('data-cy', item.config.id);
      }

      if (item.config.onItemClosed) {
        item.on('itemDestroyed', (event: any) => {
          if (get(event, 'origin.config.parentKey') === 'editorTabs') {
            item.config.onItemClosed(event);
          }
        });
      }
    });
    this.glLayout.init();
    this.stackListeners = {};
    this.compListerners = {};
    this.registerListeners();
    tabScroll();
  }

  registerListeners() {
    if (this.glLayout.root) {
      this.glLayout.root.getItemsByType('component').forEach((comp: any) => {
        if (this.compListerners[comp.config.id]) {
          return;
        }
        this.compListerners[comp.config.id] = true;
        comp.setSize = () => {
          if (comp.element.is(':visible')) {
            // Do not update size of hidden components to prevent unwanted reflows
            comp.container._$setSize(
              comp.element.width(),
              comp.element.height(),
            );
          }
        };
      });

      this.glLayout.root.getItemsByType('stack').forEach((stack: any) => {
        if (this.stackListeners[stack.config.id]) {
          return;
        }

        this.stackListeners[stack.config.id] = true;

        /* Do not remove, this is a ugly hack to force GL to not put the tabs in their ugly dropdown */
        stack.header._tabControlOffset = -1000000;
        let lastActiveItem: any = null;
        if (stack.config.onActiveItemChanged) {
          const original = stack.setActiveContentItem.bind(stack);

          stack.setActiveContentItem = (newActiveItem: any) => {
            if (lastActiveItem === newActiveItem) {
              return;
            }
            if (newActiveItem.config.setActiveOnInsert === false) {
              newActiveItem.container._element.hide();
              newActiveItem.config.setActiveOnInsert = true;
              return;
            }
            newActiveItem.config.setActiveOnInsert = true;
            lastActiveItem = newActiveItem;
            stack.config.onActiveItemChanged(newActiveItem);
            original(newActiveItem);
          };
        }
      });
    }
  }

  register(comp: any) {
    if (isArray(comp.content)) {
      each(comp.content, (c) => this.register(c));
    } else if (comp.component && comp.reactComponent) {
      attempt(() =>
        this.glLayout.registerComponent(
          comp.component,
          this.wrapper(comp.reactComponent, comp.id),
        ),
      );
    }
  }

  applyDiff(diff: any) {
    const orderMap = {
      R: 1,
      A: 2,
      I: 3,
    };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    sortBy(diff, (d) => orderMap[d.kind]).forEach((d) => {
      if (d.kind === 'R') {
        const itemToRemove = this.glLayout.root.getItemsById(d.id)[0];
        itemToRemove.remove();
      } else if (d.kind === 'A') {
        const parent = this.glLayout.root.getItemsById(d.inItem)[0];
        this.register(d.item);
        const newContentItem = this.glLayout.createContentItem(d.item);
        newContentItem.callDownwards('_$init');
        parent.addChild(newContentItem, d.at);
      } else if (d.kind === 'I') {
        const parent = this.glLayout.root.getItemsById(d.parent)[0];
        const activeItem = this.glLayout.root.getItemsById(d.activeItemId)[0];
        parent.setActiveContentItem(activeItem);
      }
    });
  }

  componentWillReceiveProps(nextProps: any) {
    const diff = computeConfigDiff(
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'config' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.config.content[0],
      nextProps.config.content[0],
      [0],
    );
    this.applyDiff(diff);
    this.registerListeners();
    tabScroll();
  }

  componentWillUnmount() {
    disposeScrolls();
    window.removeEventListener('resize', this.onWindowResize);
    this.glLayout.off('stateChanged', this.onStateChange);
    this.glLayout.destroy();
  }

  render() {
    return (
      <div
        id="rendered-view"
        ref={(r) => {
          this.root = r;
        }}
      />
    );
  }
}

export default GoldenComponent;
