/** @jsx jsx */
import { tokens } from '@datacamp/waffles/tokens';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import DCLink from '../DCLink';

type Props = {
  items?: Array<{
    id: string;
    label: string;
    link?: string;
    progress: number;
  }>;
};

export default function ProgressIndicator({ items }: Props) {
  const { t } = useTranslation();

  return items ? (
    <ul
      data-cy="progress-container"
      css={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 600,
        listStyle: 'none',
        margin: 0,
        padding: 0,
        gap: tokens.spacing.small,
      }}
    >
      {items.map((item) => (
        <li
          key={item.id}
          css={{
            flexGrow: 1,
            maxWidth: 100,
          }}
        >
          <Tooltip
            content={t('ProgressIndicator.tooltip', {
              label: item.label,
              percent: item.progress,
            })}
            placement="top"
          >
            <DCLink
              data-testid="progress-indicator-item"
              css={{
                display: 'block',
                height: 8,
                borderRadius: tokens.borderRadius.medium,
                backgroundColor: tokens.colors.transparentGrey,
                borderBottom: 0,
                outline: 0,
                ':focus-visible': {
                  boxShadow: `0 0 0 2px ${tokens.colors.blueDark}`,
                },
              }}
              to={item.link}
            >
              <div
                css={{
                  transition: 'width 250ms linear',
                  height: 8,
                  borderRadius: tokens.borderRadius.medium,
                  backgroundColor: tokens.colors.blue,
                }}
                style={{ width: `${item.progress}%` }}
              />
            </DCLink>
          </Tooltip>
        </li>
      ))}
    </ul>
  ) : null;
}
