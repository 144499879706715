/** @jsx jsx */
import { Heading } from '@datacamp/waffles/heading';
import { Exercise as ExerciseIcon } from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import cn from 'classnames';
import type { TFunction } from 'i18next';
import includes from 'lodash/includes';
import startCase from 'lodash/startCase';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ExamInstructions from '../../../containers/Assignment/Instructions/ExamInstructions';
import MultipleChoiceInstructions from '../../../containers/Assignment/Instructions/MultipleChoiceInstructions';
import NormalInstructions from '../../../containers/Assignment/Instructions/NormalInstructions';
import { ListView } from '../../../shared-components';
import Assignment from '../../Assignment/Assignment';
import ExerciseCompleted from '../../Assignment/ExerciseCompleted';
import BulletInstructions from '../../Assignment/Instructions/BulletInstructions';
import TabInstructions from '../../Assignment/Instructions/TabInstructions';
import hcCollapsePanel from '../../HighOrderComponents/hcCollapsePanel';

import BulletInstructionHeader from './BulletInstructionHeader';
import NormalInstructionHeader from './NormalInstructionHeader';
import TabInstructionHeader from './TabInstructionHeader';

const instructionsComponents = {
  MultipleChoiceExercise: MultipleChoiceInstructions,
  TabExercise: TabInstructions,
  TabConsoleExercise: TabInstructions,
  BulletExercise: BulletInstructions,
  BulletConsoleExercise: BulletInstructions,
  ExamExercise: ExamInstructions,
};

const instructionHeaderComponents = {
  TabConsoleExercise: TabInstructionHeader,
  TabExercise: TabInstructionHeader,
  BulletExercise: BulletInstructionHeader,
  BulletConsoleExercise: BulletInstructionHeader,
};

/**
 * Because the tabs exercises have controls inside the listview headers,
 * the click would cause the listview to scroll to the instructions.
 * For now, we disable the "click to scroll" feature of the listview for
 * these exercises.
 * @see: https://github.com/facebook/react/issues/1691
 * TODO: find a solution to make it work for tab exercises.
 */
const disabledListviewHeaderClickExercises = [
  'TabConsoleExercise',
  'TabExercise',
];

type OwnProps = {
  assignment: string;
  chapterId: number;
  id: number;
  isClosed?: boolean;
  isCompleted?: boolean;
  sidebarStyle?: unknown;
  t: TFunction;
  title: string;
  type: string;
  user?: {
    completed?: unknown;
  };
  userHasSubscription: boolean;
  xp?: number;
};

const defaultProps = {
  sidebarStyle: {},
  isCompleted: false,
  xp: 0,
  user: { completed: {} },
};

type Props = OwnProps & typeof defaultProps;

class ExerciseAside extends Component<Props> {
  static defaultProps = defaultProps;

  collapseButtonStyle: any;

  constructor(props: Props) {
    super(props);

    // Collapse button position depending on scrollbar width
    this.collapseButtonStyle = { right: 0 };
  }

  render() {
    const {
      assignment,
      children,
      id,
      isClosed,
      isCompleted,
      sidebarStyle,
      t,
      title,
      type,
      user,
      xp,
    } = this.props;
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    const Instruction = instructionsComponents[type] || NormalInstructions;
    const InstructionHeader =
      // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
      instructionHeaderComponents[type] || NormalInstructionHeader;
    const hideXp = includes(['TabExercise', 'BulletExercise'], type);
    const headersJumpToSection = !includes(
      disabledListviewHeaderClickExercises,
      type,
    );
    const listViewItems = [
      {
        header: (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              paddingRight: tokens.spacing.medium,
            }}
          >
            <Heading
              size="xlarge"
              css={{
                fontSize: tokens.fontSizes.large,
                margin: 0,
                display: 'flex',
                gap: tokens.spacing.small,
                alignItems: 'center',
                paddingLeft: tokens.spacing.medium,
              }}
            >
              <ExerciseIcon size="small" />
              {startCase(t('glossary:exercise', { count: 1 }))}
            </Heading>
          </div>
        ),
        content: <Assignment {...{ assignment, title, hideXp, xp }} />,
      },
      {
        header: <InstructionHeader xp={xp} />,
        content: (
          <div>
            <Instruction isCompleted={isCompleted} />
          </div>
        ),
      },
    ];

    const exerciseCompletedWindow = isCompleted && (
      // @ts-expect-error TS2741
      <ExerciseCompleted {...user.completed} id={id} />
    );

    return (
      <aside
        className={cn('exercise--sidebar', { closed: isClosed })}
        style={sidebarStyle}
      >
        <div className="exercise--sidebar-content">
          <ListView
            key={listViewItems.length}
            headerHeight={33}
            headersStickAtTop
            headersStickAtBottom
            items={listViewItems}
            headersJumpToSection={headersJumpToSection}
          />
        </div>
        {exerciseCompletedWindow}
        {children}
      </aside>
    );
  }
}

export default hcCollapsePanel({ fromTopToBottom: false })(
  withTranslation(['glossary'])(ExerciseAside),
);
