/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { ArrowLeft, ArrowRight, Expand } from '@datacamp/waffles/icon';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import type GoldenLayout from 'golden-layout';
import type { TFunction } from 'i18next';
import { Component } from 'react';
import { render } from 'react-dom';
import { withTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import { SlidesComponent } from '../Slides';

const SOURCE_TYPES = {
  img: 'img',
  iframe: 'iframe',
  rawIframe: 'rawIframe',
  pdf: 'pdf',
};

const sourceStyles = css`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

type Props = {
  currentStatus?: string;
  glContainer?: GoldenLayout.Container;
  nextButtonTitle?: string;
  onExpand?: (...args: any[]) => any;
  onResize?: (...args: any[]) => any;
  onShowNext?: (...args: any[]) => any;
  onShowPrevious?: (...args: any[]) => any;
  previousButtonTitle?: string;
  // TODO: PropTypes.oneOf(Object.keys(SOURCE_TYPES))
  sourceData?: string;
  sourceType?: any;
  t: TFunction;
  uiTheme?: string;
};

class GraphicalOutput extends Component<Props> {
  expandEl: any;

  expandedWindow: any;

  iframeEl: any;

  constructor(props: Props) {
    super(props);
    this.expandEl = null;
    this.iframeEl = null;
    this.onExpand = this.onExpand.bind(this);
    this.onResize = this.onResize.bind(this);
    this.expandedWindow = null;
  }

  componentDidMount() {
    this.refreshExpandIcon();
    if (this.props.sourceType === SOURCE_TYPES.rawIframe) {
      this.refreshIframeContent();
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.refreshExpandIcon();
    if (
      this.props.sourceType === SOURCE_TYPES.rawIframe &&
      (prevProps.sourceType !== SOURCE_TYPES.rawIframe ||
        prevProps.sourceData !== this.props.sourceData)
    ) {
      this.refreshIframeContent();
    }
  }

  onExpand() {
    if (this.expandedWindow) {
      this.expandedWindow.focus();
    } else if (this.props.onExpand != null) {
      this.props.onExpand();
    }
  }

  onResize(width: any, height: any) {
    /**
     * react-resize-detector triggers a resize when an item is hidden from
     * GoldenLayout with 0 as dimensions. In that case, calling onResize prop is
     * prevented.
     */
    if (width !== 0 && height !== 0 && this.props.onResize != null) {
      this.props.onResize(width, height);
    }
  }

  refreshExpandIcon() {
    if (this.props.onExpand == null) {
      if (this.expandEl != null) {
        this.expandEl.parentNode.removeChild(this.expandEl);
        this.expandEl = null;
      }
    } else {
      if (this.expandEl == null) {
        this.expandEl = document.createElement('span');
        if (this.props.glContainer && this.props.glContainer.tab) {
          this.props.glContainer.tab.element.append(this.expandEl);
        } else if (this.props.glContainer) {
          this.props.glContainer.on('tab', (tab: GoldenLayout.Tab) => {
            tab.element.append(this.expandEl);
          });
        }
      }
      const icon = (
        <Button
          variant="plain"
          aria-label="Expand tab"
          size="small"
          onClick={this.onExpand}
          icon={<Expand />}
          inverted={this.props.uiTheme === 'DARK'}
          css={{
            color:
              this.props.uiTheme === 'DARK'
                ? tokens.colors.white
                : tokens.colors.navy,
            marginLeft: tokens.spacing.small,
            width: 20,
            height: 20,
            minWidth: 20,
          }}
        />
      );

      render(icon, this.expandEl);
    }
  }

  refreshIframeContent() {
    requestAnimationFrame(() => {
      if (this.iframeEl == null || this.props.sourceData == null) {
        return;
      }
      this.iframeEl.contentDocument.open();
      this.iframeEl.contentDocument.write(this.props.sourceData);
      this.iframeEl.contentDocument.close();
    });
  }

  render() {
    const {
      currentStatus,
      onResize,
      onShowNext,
      onShowPrevious,
      sourceData,
      sourceType,
      t,
    } = this.props;

    if (sourceData == null) {
      return null;
    }

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          data-cy="graphical-output-content-container"
          css={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {onResize != null && (
            <ReactResizeDetector
              handleWidth
              handleHeight
              onResize={this.onResize}
            />
          )}
          {sourceType === SOURCE_TYPES.img && (
            <img
              alt="plot"
              role="presentation"
              src={sourceData}
              data-test-id="plot-image"
              data-cy="plot-image"
              css={sourceStyles}
            />
          )}
          {sourceType === SOURCE_TYPES.iframe && (
            <iframe
              title="HTML output"
              src={sourceData}
              frameBorder="0"
              css={sourceStyles}
            />
          )}
          {sourceType === SOURCE_TYPES.rawIframe && (
            <iframe
              title="HTML output"
              src="about:blank"
              frameBorder="0"
              css={sourceStyles}
              ref={(el) => {
                this.iframeEl = el;
              }}
            />
          )}
          {sourceType === SOURCE_TYPES.pdf && (
            <SlidesComponent slides_link={sourceData} />
          )}
        </div>
        {currentStatus !== null && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: tokens.spacing.small,
            }}
            data-cy="plot-number"
          >
            <Button
              variant="secondary"
              onClick={onShowPrevious != null ? onShowPrevious : () => {}}
              iconLeft={<ArrowLeft />}
              disabled={onShowPrevious == null}
              data-cy="graphical-output-previous-action"
            >
              {this.props.previousButtonTitle ??
                t('GraphicalOutput.previousButton')}
            </Button>
            <Text data-cy="plot-currentStatus">{currentStatus}</Text>
            <Button
              variant="secondary"
              onClick={onShowNext != null ? onShowNext : () => {}}
              iconRight={<ArrowRight />}
              disabled={onShowNext == null}
              data-cy="graphical-output-next-action"
            >
              {this.props.nextButtonTitle ?? t('GraphicalOutput.nextButton')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(GraphicalOutput);
export { SOURCE_TYPES };
