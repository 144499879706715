// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { connect } from 'react-redux';

import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import Header from './Header';

const mapStateToProps = (state: any) => {
  const chapterWindow = selectors.selectChapterWindow(state).toJS();
  const minimal = Boolean(
    chapterWindow.type === 'screen' && chapterWindow.show,
  );

  return {
    exerciseType: selectors.selectExercise(state).get('type'),
    courseId: selectors.selectCourse(state).get('id'),
    technology: selectors.selectTechnology(state),
    topic: selectors.selectTopic(state),
    courseSlug: selectors.selectCourse(state).get('slug'),
    isCaseStudy: selectors.selectCourse(state).get('case_study') ?? false,
    courseTitle: selectors.selectCourseTitle(state),
    isSlide: Boolean(selectors.selectChapter(state).get('slides_link', false)),
    showNoteTaking: selectors.showNoteTaking(state),
    user: selectors.selectUser(state).toJS(),
    minimal,
    isPeekVideoButtonVisible: !isNil(
      selectors.selectPrecedingVideoExercise(state),
    ),
    isMobileButtonVisible: selectors.selectShouldShowMobileButton(state),
    learningMode: selectors.selectLearningMode(state),
    categoryPages: selectors.selectCategoryPages(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  showLoginModal: () =>
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.LOGIN })),

  onClickOnIssueReporter: () =>
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.ISSUE_REPORTER })),

  onClickPeekVideoButton: () => {
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.VIDEO }));
  },

  onClickMobileButton: () => {
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.MOBILE_POPUP }));
  },

  activeSlide: ({ category }: any) =>
    dispatch(actions.updateActiveTab({ category, activeKey: 'slides' })),
});

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onClickPeekVideoButton: stateProps.isPeekVideoButtonVisible
    ? dispatchProps.onClickPeekVideoButton
    : noop,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Header);
